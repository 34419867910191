import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ChannelStatus = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://vault645node.down2earthgg.repl.co/');
        const newData = response.data;
        setData(newData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className='md:ml-8 md:pr-28 lg:pr-32 mb-4 border-orange-600 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 '>
      {data.map(item => (
        <div id='video' className='p-12 lg:m-8 lg:p-4 w-full xl:max-w-xl xl:mx-auto flex items-center justify-center'
          key={item.title}
        >
          <iframe
            className="w-full h-60 lg:min-h-[300px] md:h-98 border-2 border-orange-600 drop-shadow-2xl shadow-[0_25px_32px_0_rgba(255,100,0,0.3)]"
            title="Fargassiers' Latest Video"
            src={`https://www.youtube.com/embed/${item}?&fs=1&rel=0&showinfo=1&branding=0&vq=hd1080`}
            allow="fullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      ))}
    </div>
  );
};

export default ChannelStatus;

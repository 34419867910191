import React from 'react';
import caps from '../assets/images/caps.png'

function Merch() {
  const items = [

    {
      id: 1,
      title: '"The Mothman Hug"',
      description: `Embrace the enigmatic world of cryptids with our "Mothman Hug" T-shirt. Featuring a captivating design, it's perfect for fans of the mysterious!`,
      caps: '15.99',
      alt: 'Fargassier Tshirt',
      image: 'https://imgproxy.fourthwall.com/_lgYp1qAAsoWGCVpMFEQQGTe6iv--FIx_LcvDT0papg/w:900/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_8dec92d0-02c3-4245-bdd3-a34c451f2fde/efcb9a16-9637-49eb-971f-b14d8c05058d.png',
      link: 'https://fargassier-shop.fourthwall.com/products/mothman-free-hugs-shirt',
      cta: 'Order Now',
      color: '#52b514'
    },
    {
      id: 2,
      title: 'Vault Yellow 645 ',
      description: 'Unearth the mystery of the "Secret Vault" shirt, inspired by Fallout. Delve into the unknown experiments within. Not affiliated with Fallout. Now in Vault Yellow.',
      caps: '15.99',
      alt: 'Fargassier Tshirt',
      image: 'https://imgproxy.fourthwall.com/5JSmaknG8MWIMALMEeZVQhADXa_pXq8spclfPOiDQas/w:900/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_8dec92d0-02c3-4245-bdd3-a34c451f2fde/cd5fc2af-6f90-4dd7-88a7-06f411b89875.png',
      link: 'https://fargassier-shop.fourthwall.com/products/the-secret-vault-v2',
      cta: 'Order Now',
      color: '#52b514'
    },
    {
      id: 3,
      title: 'Vault 645 XXL Mouse Pad',
      description: 'Discover the hidden secrets of our "Secret Vault" Mouse Pad, inspired by the Fallout series. Transform your desk into a post-apocalyptic adventure. Not affiliated with Fallout.',
      caps: '24.99',
      alt: 'Fargassier Tshirt',
      image: 'https://imgproxy.fourthwall.com/1VXy39OI5EjN8eMEAcGNxqm2q2KiN7Pnxoc-OIV8HG8/w:900/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_8dec92d0-02c3-4245-bdd3-a34c451f2fde/2b1ff9d8-a045-4ad2-9b90-f9a0f03471f3.png',
      link: 'https://fargassier-shop.fourthwall.com/products/vault-645-mouse-pad-2',
      cta: 'Order Now',
      color: '#52b514'
    },
    {
      id: 4,
      title: 'Atom Bomb T-Shirt',
      description: `Show your love for the Freshen Up drink "Atom Bomb" with our exclusive T-Shirt. Designed by @FellanaTheirin, it's perfect for supporting your favorite Gassy creator.`,
      caps: '15.99',
      alt: 'Fargassier Tshirt',
      image: 'https://imgproxy.fourthwall.com/qHchuz5jOCMel_rn4YE_4TtnaAkgbYlr__pZKqObVlc/w:900/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_8dec92d0-02c3-4245-bdd3-a34c451f2fde/6217aae4-4968-4859-9d5a-1d82cf510303.png',
      link: 'https://fargassier-shop.fourthwall.com/products/atom-bomb-t-shirt',
      cta: 'Order Now',
      color: '#52b514'
    },
    {
      id: 5,
      title: 'Vault Blue 645',
      description: 'Unearth the mystery of the "Secret Vault" shirt, inspired by Fallout. Delve into the unknown experiments within. Not affiliated with Fallout. Now in Vault Blue!',
      caps: '15.99',
      alt: 'Fargassier Tshirt',
      image: 'https://imgproxy.fourthwall.com/WYUECC1vbR2jBd1dSJTB5NYmOsLcnzFkuTBetAOjgB4/w:900/plain/https://storage.googleapis.com/cdn.fourthwall.com/offer/sh_8dec92d0-02c3-4245-bdd3-a34c451f2fde/02dfb3a9-8e6b-4036-aebe-af3feb21c10a.png',
      link: 'https://fargassier-shop.fourthwall.com/products/the-secret-vault',
      cta: 'Order Now',
      color: '#52b514'
    },

    {
      id: 6,
      title: 'Atom Bomb Poster ',
      description: `Elevate your fandom for the Atom Bomb drink and your beloved Gassy creator with our stunning poster. Created by @FellanaTheirin, it's the perfect way to show your support.`,
      caps: '19.99',
      alt: 'Fargassier Tshirt',
      image: 'https://cdn.fourthwall.com/customization/sh_8dec92d0-02c3-4245-bdd3-a34c451f2fde/8d0942c3-05cd-4a20-8901-cdbc729fddc9.jpeg',
      link: 'https://fargassier-shop.fourthwall.com/products/atom-bomb-poster',
      cta: 'Order Now',
      color: '#52b514'
    },

    
  ];

  return (
    <div id='merch' className="p-8 border-orange-600 border-b-4 md:ml-10 md:pr-24 gap-8 grid md:grid-cols-2 lg:grid-cols-3 justify-center">
      {items.map((item, index) => (
        <article
          key={item.id}
          className='mx-auto rounded-t-3xl xl:max-w-lg p-8 bg-[#325886] border-4 border-[#fef265]'
        >
          <img
            alt={item.alt}
            src={item.image}
            className="mx-auto w-[400px] h-[600px] object-cover rounded-tr-3xl rounded-bl-3xl border-2 border-[#fef265]"
          />

          <div className='relative bottom-0'>
            <div className="bg-[#fef265]">
              <h3 className="font-mono animate-pulse text-[#447ab9] my-4 p-2 text-center  font-bold uppercase text-xl">{item.title}</h3>
            </div>
            <div className='mb-6 '>
              <p className="text-[#008e00] text-center font-semibold text-sm leading-snug">{item.description}</p>
            </div>
            <div className='flex justify-center items-center text-[#008e00] text-center p-2 font-mono font-bold text-3xl'>
              <img className='w-10 lg:w-14 m-2 mr-4' src={caps} alt='Red Bottle Cap Icon with USD symbol'/>
              <span className='-ml-2 text-[#00ee00]'>{item.caps}</span>
            </div>

            <div className='bg-[#008e00]  hover:bg-[#00ee00] text-center p-2'>

            <a
              href={item.link}
              className="text-[#005f00] font-mono p-4 px-6 uppercase font-bold tracking-widest text-base transition duration-300"
            >
              {item.cta}
            </a>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
}

export default Merch;

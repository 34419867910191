import React, { useState } from "react";
import "./App.css";
import Navigation from "./components/Navigation.js";
import SideNavigation from "./components/SideNavigation.js";
import Content from "./components/Content";

function App() {
  const [activeLink, setActiveLink] = useState("Home");
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <div className="-mt-16">
        <div className="z-50 hidden md:flex md:ml-[90px]">
          <SideNavigation activeLink={activeLink} onLinkClick={handleLinkClick} />
        </div>
        <div className="md:hidden z-50 fixed bottom-0 border-black">
          <Navigation activeLink={activeLink} onLinkClick={handleLinkClick} />
        </div>
        <div>
           <Content />
        </div>

    </div>
  );
}

export default App;

import React from 'react';
import './Navigation.css';
import { IonIcon } from '@ionic/react';
import { home, personAdd, logoYoutube, cart, calendar} from 'ionicons/icons';


function NavItem({ activeLink, handleClick, icon, text, color, href }) {
  return (
    <li className={`list ${activeLink === text ? 'active' : ''}`}>
      <a href={href} onClick={() => handleClick(text)}>
        <span className="icon">
          <IonIcon icon={icon} />
          
        </span>
        <span className="text">{text}</span>
      </a>
    </li>
  );
}

function Navigation({ activeLink, onLinkClick }) {
  const handleClick = (link) => {
    onLinkClick(link);
    const target = document.querySelector(`#${link.toLowerCase()}`);
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  }

  const navItems = [
    { icon: logoYoutube, text: "Videos", color: "#f44336", href: "#video" },
    { icon: personAdd, text: "About", color: "#ffa117", href: "#about" },
    { icon: home, text: "Home", color: "#0fc70f", href: "#home" },
    { icon: cart, text: "Merch", color: "#2196f3", href: "#merch" },
    { icon: calendar, text: "Schedule", color: "#b145e9", href: "#schedule" },
  ];

  const activeNavItem = navItems.find((navItem) => navItem.text === activeLink);

  return (
    <div className="navigation">
      <ul>
        {navItems.map((navItem) => (
          <NavItem
            key={navItem.text}
            activeLink={activeLink}
            handleClick={handleClick}
            {...navItem}
          />
        ))}
        <div className="indicator" style={{ backgroundColor: activeNavItem.color }}></div>
      </ul>
    </div>
  );
}

export default Navigation;

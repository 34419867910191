import React from 'react';

const Schedule = () => {
  const schedule = [
    {
      day: 'Thursday',
      time: '7:00pm - 10:00pm EST',
    },
    {
      day: 'Friday',
      time: '7:00pm - 9:00pm EST',
    },
    {
      day: 'Saturday',
      time: '11:00am - 3:00pm EST',
    },
    {
      day: 'Sunday',
      time: '11:00am - 3:00pm EST',
    },
  ];

  const uploads = [
    {
      day: 'Monday',
      content: 'Video'
    },
    {
      day: 'Tuesday',
      content: 'Atomic Shop Review'
    },
    {
      day: 'Wednesday',
      content: 'Video'
    },
    {
      day: 'Friday',
      content: 'Video'
    },
    {
    day: 'Sunday',
    content: 'Fallout News Recap'
    },
  ];

  return (
    <div id='schedule' className="md:ml-14 md:mr-28 text-[#325886] mx-auto rounded-3xl xl:max-w-7xl bg-[#325886] border-4 border-[#fef265] my-8 mb-32 lg:mb-8">
      <div className="pt-8 px-4">
        <h2 className=" text-center text-3xl font-extrabold text-[#2196f3]  uppercase">Streaming Schedule</h2>
        <div className=" mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 ">
          {schedule.map((item) => (
            <div
              key={item.day}
              className="bg-[#2196f3] text-black shadow overflow-hidden sm:rounded-lg border-4 border-[#fef265]"
            >
              <div className="px-4 py-5 sm:px-6 bg-[#fef265]">
                <h3 className="text-lg leading-6 font-medium text-gray-900 border-black border-b">
                  {item.day}
                </h3>
                <p className="mt-1 max-w-3xl text-sm text-black">
                  {item.time}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="rounded mb-8 mt-8">
        <h2 className=" text-center text-3xl font-extrabold text-[#2196f3]  uppercase">Video Uploads</h2>
        <div className="mt-8 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-5 ">
        {uploads.map((item) => (
            <div
              key={item.day}
              className=" text-black shadow overflow-hidden sm:rounded-lg border-4 border-[#fef265]"
            >
              <div className="px-4 py-8 sm:px-6 bg-[#fef265]">
                <h3 className="text-lg leading-6 font-medium text-gray-900 border-black border-b">
                  {item.day}
                </h3>
                <p className="mt-1 max-w-3xl text-sm text-black">
                  {item.content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};

export default Schedule;
import React from 'react';
import falloutgassy from '../assets/images/falloutgassy.png'


<link href="https://fonts.googleapis.com/css?family=Fira+Mono" rel="stylesheet" />
const AboutMe = () => {
  return (
    <section className="screen bg-black py-8 border-y-4 border-orange-600 md:pr-14">
      <div className="mx-auto px-4 sm:px-6 lg:px-8">
        <div id='about' className="lg:text-center">
          <h2 className="text-lg text-[#005f00] font-bold tracking-wide uppercase font-mono">About Me</h2>
          <p className="font-mono mt-2 text-4xl leading-10 font-extrabold tracking-tight border-2 text-center p-2 border-[#00ee00] text-[#00ee00] sm:text-5xl">
            THE GASSIEST GAMER
          </p>
          <div className="mt-4 flex justify-center">
            <img
              className="h-96 object-contain rounded-xl scale-x-[-1]"
              src={falloutgassy}
              alt="Fargassiers Fallout Persona"
            />
          </div>
          <div className=" mt-8 max-w-5xl mx-auto mb-32">
            <div className="flex justify-between my-8">
              <p className="p-2 font-mono text-xl border-2 border-[#00ee00] font-semibold text-[#00ee00]">LEVEL</p>
              <p className="font-mono text-lg font-semibold text-[#00ee00]">36</p>
            </div>
            <div className="flex justify-between my-8">
              <p className="p-2 font-mono text-xl border-2 border-[#00ee00] font-semibold text-[#00ee00]">SPECIAL</p>
              <p className="font-mono text-lg font-semibold text-[#00ee00]">S:5 P:7 E:3 C:4 I:9 A:2 L:1</p>
            </div>
            <div className="flex justify-between my-8">
              <p className="p-2 font-mono text-xl border-2 border-[#00ee00] font-semibold text-[#00ee00]">Skills:</p>
              <p className="font-mono text-lg font-semibold text-[#00ee00]">Gaming, Burps, Heckin' dying</p>
            </div>
            <div className="flex justify-between my-8">
              <p className="p-2 font-mono text-xl border-2 border-[#00ee00] font-semibold text-[#00ee00]">Equipment:</p>
              <p className="font-mono text-lg font-semibold text-[#00ee00]">Water, WIFE, Keyboard</p>
            </div>
          </div>
        </div>
      </div>
      
    </section>
  );
};

export default AboutMe;
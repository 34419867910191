import React from "react";
import ReactPlayer from "react-player";
import Popup from "reactjs-popup";
import ChannelStatus from "./ChannelStatus";
import AboutMe from "./AboutMe";
import Schedule from "./Schedule";
import Merch from "./Merch";
import video from "../assets/video/fargassier.mp4";
import image from "../assets/images/FargassierBody.png";
import gassyText from "../assets/images/fargassiertext.png";
import dixper from "../assets/images/cookies.svg";
import guilded from "../assets/images/Guilded_Logomark_Black.png";
import hydrate from "../assets/images/Freshen Up Hydration Logo.png";
import vault645 from "../assets/images/Vault645_Yellow.png";

function Content() {
  const Modal = () => (
    <Popup
      trigger={
        <a href="#popup" className="hidden md:block shadow-xl">
          <img
            className="bg-[#2196f3] border border-white object-contain rounded-xl h-36 w-36 shadow-black p-4 hover:bg-slate-500"
            src={vault645}
            alt="Vault645 logo"
          />
        </a>
        
      }
      modal
      nested
    >
      {(close) => (
        <div className="modal">
          
          <button className="close" onClick={close}>
            
            &times;
          </button>
          <div className="header font-mono"> Vault 645 </div>
          <div className="content font-mono">
             <p>You have found a secret vault!</p>
            <img
            className="bg-[#2196f3] w-full object-contain rounded-xl h-36 shadow-black p-4"
            src={vault645}
            alt="Vault645 logo"
          />
            <p>Would you like to Enter?</p>
          </div>
          <div className="actions">
            
            <button className="button"> 
            <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" >
              Enter Vault
            </a>
            </button>
            {/* <button className="button"> 
              Special Button
            </button> */}
            <button
              className="button"
              onClick={() => {
                close();
              }}
            >
              Leave
            </button>
          </div>
        </div>
      )}
    </Popup>
  );

  return (
    <div className="w-screen scroll-x-0 h-full md:ml-32 bg-black">
      <div className="mt-16border-b-4 my-8 border-orange-600">
        <ReactPlayer
          id="home"
          url={video}
          width="100%"
          height="100%"
          playing
          loop
        />
        {/* <div className="hidden lg:flex absolute z-10 bottom-28 xl:left-36 xl:top-80 scale-[50%] xl:scale-[75%]">
        <PipBoy />
      </div> */}
        <img
          className="absolute top-8 lg:h-96 opacity-70 md:left-32"
          src={gassyText}
          alt="A font logo for the Streamer, Fargassier."
        />

        <img
          className="absolute top-12 md:left-48 lg:left-96 z-0"
          src={image}
          alt="This is an a half-bust of the Streamer, Fargassier"
        />
      </div>

      <div className="mb-8 md:pr-12 relative mx-auto p-4 bg-gradient-to-b from-[#82a7d6] to-[#447ab9] shadow-inner shadow-slate-500 w-full border-orange-600  border-y-4 ">
        <ul className="flex flex-row gap-8 md:gap-10 justify-center ">
          <a href="https://dixper.gg/fargassier" className="shadow-xl">
            <img
              className="bg-[#0fc70f] border border-white object-contain rounded-xl h-36 w-36 pt-6 px-4 shadow-black hover:bg-slate-500"
              src={dixper}
              alt="dixper logo"
            />
          </a>
          <a href="https://www.guilded.gg/i/k5a8LPQk" className="shadow-xl">
            <img
              className="bg-[#fef265] border border-white object-contain rounded-xl h-36 w-36 shadow-black -mb-4 hover:bg-slate-500"
              src={guilded}
              alt="Guilded.gg logo"
            />
          </a>
          <a
            href="https://www.freshenuphydration.com/?ref=GASSY"
            className="shadow-xl"
          >
            <img
              className="bg-[#ffa117] border border-white object-contain rounded-xl h-36 w-36 shadow-black p-4 hover:bg-slate-500"
              src={hydrate}
              alt="Hydrate Energy logo"
            />
          </a>
          <Modal />
        </ul>
      </div>

      <div>
        <ChannelStatus />
      </div>

      <div>
        <div className="">
          <AboutMe />
        </div>
      </div>

      <div>
        <div className="">
          <Merch />
        </div>
      </div>

      <div className="flex items-center justify-center">
          <Schedule />
        </div>
      </div>  
  );
}

export default Content;

import { useState } from "react";
import "./SideNavigation.css";
import { IonIcon } from "@ionic/react";
import { home, personAdd, logoYoutube, cart, calendar } from 'ionicons/icons';


function SideNavigation({ activeLink, onLinkClick }) {
  const [isActive, setIsActive] = useState(false);

  const handleMenuToggle = () => {
    setIsActive(!isActive);
    setTimeout(() => {
      setIsActive(true);
    }, isActive ? 500 : 500);
  };

  const handleLinkClick = (event) => {
    event.preventDefault();
    const list = event.currentTarget.parentNode;
    const listItems = list.querySelectorAll(".list");
    listItems.forEach((item) => item.classList.remove("active"));
  
    const indicator = list.querySelector(".indicator");
    if (indicator) {
      const activeItem = list.querySelector(`.list.active`);
      if (isActive) {
        indicator.style.top = `${event.currentTarget.offsetTop}px`;
      } else if (activeItem) {
        indicator.style.top = `${activeItem.offsetTop}px`;
      } else {
        indicator.style.top = "";
      }
    }
  
    const linkText = event.currentTarget.text;
    onLinkClick(linkText);
  
    const href = event.currentTarget.getAttribute("href");
    const target = document.querySelector(href);
    if (target) {
      const topOffset = window.innerHeight / 2 - target.offsetHeight / 2;
      window.scrollTo({
        top: target.offsetTop - topOffset,
        behavior: "smooth",
      });
      setIsActive(!isActive);
      if (!isActive) {
        setTimeout(() => {
          setIsActive(false);
        }, 1000);
      }
    } else {
      setIsActive(isActive);
    }
  };
  
  
  const navItems = [
    { icon: home, text: "Home", color: "#0fc70f", href: "#home" },
    { icon: logoYoutube, text: "Videos", color: "#f44336", href: "#video" },
    { icon: personAdd, text: "About", color: "#ffa117", href: "#about" },
    { icon: cart, text: "Merch", color: "#2196f3", href: "#merch" },
    { icon: calendar, text: "Schedule", color: "#b145e9", href: "#schedule" },
    
  ];

  return (
    <div className={`sideNavigation  ${isActive ? "active" : ""}`}>
      <div className="menuToggle" onClick={handleMenuToggle}></div>
      <ul>
        {navItems.map((item) => (
          <li
            className={`list ${activeLink === item.text ? "active" : ""}`}
            key={item.text}
          >
            <a
              href={item.href}
              style={{ "--clr": item.color }}
              onClick={handleLinkClick}

            >
              <span className="icon">
                <IonIcon icon={item.icon} />
              </span>
              <span className="text">{item.text}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default SideNavigation;
